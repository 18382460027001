import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function FestivalPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="c9b328b7-d5d4-49ca-bf12-ed2de551af5f" data-fixr-theme="light" data-name-filter="festival"></script>`}
      imageSrc="/event-logo-festival.png"
      imageStyle={{
        maxWidth: 440,
        // filter: "drop-shadow(-1px -1px 8px #00000050)",
      }}
      title="Fresh Festival"
    />
  );
}
