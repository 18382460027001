import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "hooks/useInView";

export function MotionImage({
  className = "",
  style = null,
  initial,
  animate,
  src,
}) {
  const imgRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const inView = useInView(imgRef, { threshold: 0.4 });

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoad();
    }
  }, []);

  return (
    <motion.img
      className={className}
      initial={initial}
      animate={loaded && inView ? animate : null}
      onLoad={onLoad}
      ref={imgRef}
      src={src}
      style={style}
    />
  );
}
